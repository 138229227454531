import Axios, { AxiosAdapter, AxiosRequestConfig } from 'axios'
import devise, { AuthHeaders } from '@/utils/auth/DeviseAuthToken'

const adapter: AxiosAdapter = (config: AxiosRequestConfig) => {
  const conf = config
  const headers = conf.headers as AuthHeaders
  conf.headers = devise.pull(headers)
  conf.adapter = undefined
  const regExp = new RegExp(`^${conf.baseURL}`)
  conf.url = conf.url.replace(regExp, '')
  const http = Axios.create()
  http.interceptors.response.use((res) => {
    devise.store(res.headers)
    return res
  })
  return http.request(conf)
}
const axios: AxiosRequestConfig = {
  baseURL: '/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true,
  adapter,
}
export default axios
