import cookies from 'js-cookie'
import storage from '@/utils/AmazonesStorage'
import _get from 'lodash/get'

export type AuthHeaders = { [key: string]: string | number | boolean }
export const authHeaderKeys = ['access-token', 'client', 'uid', 'token-type'] as const
const tokenExpires = 'expiry'
const secure = process.env.NODE_ENV === 'production'

class DeviseAuthToken {
  get isAuthenticated(): boolean {
    let isAuthenticated = true
    authHeaderKeys.forEach((key) => {
      isAuthenticated = isAuthenticated && !!cookies.get(`user.${key}`)
    })
    return isAuthenticated
  }

  store(headers: AuthHeaders): void {
    const expires = new Date(_get(headers, tokenExpires, 0) * 1000)
    authHeaderKeys.forEach((key) => {
      if (headers[key]) {
        cookies.set(`user.${key}`, encodeURIComponent(headers[key]), { path: '/', secure, expires })
      }
    })
  }

  pull(headers: AuthHeaders): AuthHeaders {
    authHeaderKeys.forEach((key) => {
      headers[key] = decodeURIComponent(cookies.get(`user.${key}`))
    })
    return headers
  }

  clear(): void {
    authHeaderKeys.forEach((key) => {
      cookies.remove(`user.${key}`, { path: '/' })
      cookies.remove(`user.${key}`, { path: '' })
      cookies.remove(`user.${key}`)
    })
  }

  logout(): void {
    this.clear()
    storage.currentUrl = null
    location.href = '/logout?' + new Date().getTime()
  }
}

export default new DeviseAuthToken()
